import React from "react";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Header from "root/sections/Header";
import AboutHero from "root/sections/AboutHero";
import AboutOurStory from "root/sections/AboutOurStory";
import AboutTeam from "root/sections/AboutTeam";
import AboutJoinUs from "root/sections/AboutJoinUs";
import Footer from "root/sections/Footer";

const METATAGS = {
  title: "Drivit | About",
  description:
    "Drivit is an award-winning driving technology that turns any smartphone into a high-quality telematics device. Drivit brings the full potential of mobility apps to the driver’s hands.",
  keywords:
    "Driving analytics, Telematics, Connected car, AI driving analytics, Artificial intelligence telematics, AI tech company, AI tech company Lisbon, ML tech company Lisbon, Tech company in Lisbon, Driving app environmental friendly, Innovative tech company in Lisbon, Get driving analytics for cars",
};

const AboutPage = () => {
  return (
    <div>
      <SEO
        title={METATAGS.title}
        description={METATAGS.description}
        keywords={METATAGS.keywords}
      />
      <Layout>
        <Header currentPage="about" background="white" />
        <AboutHero />
        <AboutOurStory />
        <AboutTeam />
        <AboutJoinUs />
        <Footer />
      </Layout>
    </div>
  );
};

export default AboutPage;
