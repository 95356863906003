import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Typography from "root/components/Typography";
import Section from "root/components/Section";
import arrow from "root/assets/arrow.svg";

import styles from "./index.module.css";

const AboutTeam = () => {
  const data = useStaticQuery(graphql`
    query {
      joinUsImg: file(relativePath: { eq: "about-join.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 678, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <div className={styles.greySection}>
        <div className={styles.imgSection}>
          <div className={styles.imgSectionContent}>
            <div className={styles.imgBox}>
              <Img
                className={styles.imgRight}
                alt="team working"
                fluid={data.joinUsImg.childImageSharp.fluid}
                objectFit="cover"
              />
            </div>
          </div>
        </div>
        <div className={styles.contentGreySection}>
          <Section color="lightGrey">
            <div className={styles.textGreySection}>
              <Typography color="green" variant="h1" weight="bold">
                Join us
              </Typography>
              <div className={styles.firstPoint}>
                <Typography>
                  <span className={styles.bulletPoint}>&#8226;</span>
                  We want to make a difference in people&apos;s
                  <b> safety and sustainability.</b>
                </Typography>
              </div>
              <div className={styles.secondPoint}>
                <Typography>
                  <span className={styles.bulletPoint}>&#8226;</span>
                  We are eager to transform the way the world moves and to
                  <b> inspire others to do the same.</b>
                </Typography>
              </div>
            </div>
          </Section>
        </div>
      </div>
      <a className={styles.link} href="https://apply.workable.com/zego/">
        <div className={styles.greenSection}>
          <div className={styles.contentGreenSection}>
            <Typography color="white" variant="h3">
              Do you share this vision?
            </Typography>
            <Typography color="white" variant="h3" weight="bold">
              Then you&apos;ll feel at home here.
            </Typography>
            <div className={styles.joinDrivit}>
              <Typography color="white" variant="h1" weight="bold">
                Apply now
              </Typography>
              <img src={arrow} alt="" className={styles.arrow} />
            </div>
          </div>
          <div className={styles.contentGreenSectionDynamic}>
            <div className={styles.textGreenSectionDynamic}>
              <div className={styles.contentGreenSection}>
                <Typography color="white" variant="h3">
                  Do you share this vision?
                </Typography>
                <Typography color="white" variant="h3" weight="bold">
                  Then you&apos;ll feel at home here.
                </Typography>

                <div className={styles.joinDrivit}>
                  <Typography color="white" variant="h1" weight="bold">
                    Apply now
                  </Typography>
                  <img src={arrow} alt="" className={styles.arrow} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </>
  );
};

export default AboutTeam;
