import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Typography from "root/components/Typography";
import BackgroundRoute1 from "root/components/SvgImages/BackgroundRoute1";

import styles from "./index.module.css";

const AboutHero = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg2: file(relativePath: { eq: "about-hero2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 577, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.backgroundSection}>
          <div className={styles.backgroundShape1}>
            <BackgroundRoute1 />
          </div>
        </div>
        <div className={styles.textSection}>
          <Typography variant="h1" color="green" weight="bold">
            About us
          </Typography>
          <div className={styles.firstText}>
            <Typography>
              Mobility data has the power to change the way we move, making us
              more productive, safer, and more sustainable.
            </Typography>
            <div className={styles.secondText}>
              <Typography>
                Yet, the available telematics technologies still do not deliver
                the full potential of driving analytics.
                <b>
                  {" "}
                  How can we gather and analyze quality driving data to manage
                  and optimize mobility services?
                </b>
              </Typography>
            </div>
          </div>
        </div>
        <div className={styles.imgSection}>
          <div className={styles.imgSectionContent}>
            <div className={styles.leftImage}>
              <Img
                className={styles.imgLeft}
                alt="team meeting"
                fluid={data.heroImg2.childImageSharp.fluid}
                objectFit="cover"
              />
            </div>
          </div>
        </div>
        <div className={styles.textSection2}>
          <div className={styles.bottomText}>
            <Typography variant="h3" color="green">
              Drivit is the answer.
              <b>
                {" "}
                We are the perfect partner for a smarter, safer, and a more
                sustainable mobility.
              </b>
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutHero;
