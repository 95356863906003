import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import Typography from "root/components/Typography";

import styles from "./index.module.css";

const TeamFounder = ({ photo, name, description, socialLinks }) => {
  return (
    <div>
      <Img
        className={styles.founderPhoto}
        alt={`${name} photo`}
        fluid={photo.childImageSharp.fluid}
        objectFit="cover"
      />
      <div className={styles.founderName}>
        <Typography color="black" weight="bold">
          {name}
        </Typography>
      </div>
      {description.map(line => (
        <div className={styles.descriptionLine} key={line}>
          <Typography color="black" variant="smallBody">
            {line}
          </Typography>
        </div>
      ))}
      <div className={styles.socialLinks}>
        {socialLinks.map(social => (
          <a
            key={social.url}
            className={styles.socialIconA}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={styles.socialIcon} src={social.icon} alt="" />
          </a>
        ))}
      </div>
    </div>
  );
};

TeamFounder.propTypes = {
  photo: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({}),
    }),
  }).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.string),
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

TeamFounder.defaultProps = {
  description: [],
  socialLinks: [],
};

export default TeamFounder;
