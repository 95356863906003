/* eslint-disable prettier/prettier */
import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Typography from "root/components/Typography";
import BackgroundRoute3 from "root/components/SvgImages/BackgroundRoute3";

import styles from "./index.module.css";

const AboutOurStory = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg1: file(relativePath: { eq: "about-hero1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 678, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.backgroundSection}>
          <div className={styles.backgroundShape3}>
            <BackgroundRoute3 />
          </div>
        </div>
        <div className={styles.imgSection}>
          <div className={styles.imgSectionContent}>
            <div className={styles.leftImage}>
              <Img
                className={styles.imgLeft}
                alt="team working"
                fluid={data.heroImg1.childImageSharp.fluid}
                objectFit="cover"
              />
            </div>
            <div className={styles.textSection}>
              <Typography variant="h2" weight="bold">
                Our Misson
              </Typography>
              <div className={styles.firstText}>
                <Typography>
                  Drivit was born out of our belief that technology and data are
                  key in making roads safer and mobility more sustainable.
                </Typography>
              </div>
              <div className={styles.secondText}>
                <Typography weight="bold">
                  Our mission is to provide tools that can enable a new
                  generation mobility services that address everyday burdens
                  while reducing the impact that the car has on our environment
                  and our safety.
                </Typography>
              </div>
              <div className={styles.thirdText}>
                <Typography>
                  Feel free to
                  {" "}
                  <Link to="/careers" className={styles.link}>
                    join the ride
                  </Link>
                  {" "}
                  at any time.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutOurStory;
