import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Typography from "root/components/Typography";
import Section from "root/components/Section";
import TeamFounder from "root/components/TeamFounder";
import backgroundImage from "root/assets/about-team-background.svg";
import linkedinIcon from "root/assets/linkedin.svg";
import zegoLogo from "root/assets/zego2.svg";

import styles from "./index.module.css";

const queryTeam = graphql`
  query TeamQuery {
    allAdvisorsJson(sort: { fields: name, order: ASC }) {
      nodes {
        name
        description
        photo {
          childImageSharp {
            fluid(maxWidth: 500, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allFoundersJson(sort: { fields: name, order: ASC }) {
      nodes {
        name
        description
        twitter
        linkedin
        photo {
          childImageSharp {
            fluid(maxWidth: 300, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const AboutTeam = () => {
  const team = useStaticQuery(queryTeam);

  return (
    <Section color="white">
      <div className={styles.background}>
        <img className={styles.backgroundImage} src={backgroundImage} alt="" />
      </div>
      <div className={styles.root}>
        <div className={styles.team}>
          <div className={styles.title}>
            <Typography variant="h2" weight="bold">
              Our founders
            </Typography>
          </div>
          <div className={styles.founders}>
            {team.allFoundersJson.nodes.map(node => (
              <div className={styles.founder} key={node.name}>
                <TeamFounder
                  photo={node.photo}
                  name={node.name}
                  description={node.description}
                  socialLinks={[
                    {
                      icon: linkedinIcon,
                      url: node.linkedin,
                    },
                  ]}
                />
              </div>
            ))}
          </div>
          <div className={styles.title}>
            <Typography variant="h2" weight="bold">
              Drivit is a Zego Company
            </Typography>
            <a href="https://zego.com">
              <img src={zegoLogo} alt="" className={styles.logoZego} />
            </a>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AboutTeam;
