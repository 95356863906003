import React from "react";

import styles from "./backgroundRoute.module.css";

const BackgroundRoute1 = () => {
  return (
    <svg
      width="565"
      height="301"
      viewBox="0 0 565 301"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          id="route5"
          d="M385.468 108.835L313.405 87.6145L468.234 4.17554L539.99 25.4186L463.01 67.0285L542.125 90.5035L464.734 132.176L556.934 159.676L462.234 210.85L373.234 184.676L163.734 297.176L8.73438 251.176L140.734 179.676C140.734 179.676 184.635 152.791 223.578 151.098C262.522 149.404 291.234 159.676 291.234 159.676L385.468 108.835Z"
          pathLength="1694"
        />

        <radialGradient
          id="fadeCar3"
          cx="0"
          cy="0"
          fx="0"
          fy="0"
          r="70"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" stopOpacity="1" offset="0" />
          <stop stopColor="#5BBA6F" stopOpacity="0.5" offset="0.5" />
          <stop stopColor="#5BBA6F" stopOpacity="0" offset="1" />
        </radialGradient>

        <radialGradient
          id="fadeTrail3"
          cx="0"
          cy="0"
          fx="0"
          fy="0"
          r="200"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73E2A7" stopOpacity="0.7" offset="0" />
          <stop stopColor="#73E2A7" stopOpacity="0.4" offset="0.8" />
          <stop stopColor="#73E2A7" stopOpacity="0.1" offset="1" />
        </radialGradient>

        <mask id="car5" maskUnits="userSpaceOnUse">
          <use className={styles.maskCarRoute5} xlinkHref="#route5">
            <animate
              attributeName="stroke-dashoffset"
              from="108"
              to="-1586"
              dur="22s"
              repeatCount="indefinite"
            />
          </use>
        </mask>

        <mask id="trail5" maskUnits="userSpaceOnUse">
          <use className={styles.maskTrailRoute5} xlinkHref="#route5">
            <animate
              attributeName="stroke-dashoffset"
              from="353"
              to="-1341"
              dur="22s"
              repeatCount="indefinite"
            />
          </use>
        </mask>
      </defs>

      <g style={{ mask: "url(#car5)" }}>
        <circle style={{ fill: "url(#fadeCar3)" }} cx="0" cy="0" r="100">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur="22s"
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route5" />
          </animateMotion>
        </circle>
      </g>

      <g style={{ mask: "url(#trail5)" }}>
        <circle style={{ fill: "url(#fadeTrail3)" }} cx="0" cy="0" r="200">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur="22s"
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route5" />
          </animateMotion>
        </circle>
      </g>
    </svg>
  );
};

export default BackgroundRoute1;
